<template>
  <div class="design">
    <el-card class="box-card">
      <div slot="header">
        <div class="title fz-18">发布设计原稿</div>
      </div>
      <el-form size="small" class="designForm" label-width="120px"
               :rules="designFormConfig.rules"
               :model="designFormConfig.data"
               :ref="designFormConfig.formName">
        <el-form-item label="设计分类" prop="docTypeValue">
          <el-cascader
            style="width: 380px;"
            v-model="designFormConfig.data.docTypeValue"
            :options="docTypeOptions"
            @change="onDocTypeCascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item label="设计原稿名称" prop="name">
          <el-input v-model="designFormConfig.data.name"></el-input>
        </el-form-item>
        <el-form-item label="设计工具" prop="tools">
          <el-select v-model="designFormConfig.data.tools" placeholder="请选择设计工具">
            <el-option
              v-for="item in toolsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件个数" prop="number">
          <el-input :maxlength="8" v-model.number="designFormConfig.data.number"></el-input>
        </el-form-item>
        <el-form-item label="原稿大小" prop="size">
          <el-input :maxlength="10" v-model.number="designFormConfig.data.size">
            <el-select style="width: 70px;" slot="append" v-model="designFormConfig.data.sizeUnit"
                       placeholder="请选择">
              <el-option
                v-for="item in sizeUnit"
                :key="item.value"
                :label="item.label"
                :value="item.label">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="auto" label="展示封面" prop="cover">
          <el-upload
            :http-request="fileUploadFunction"
            action=""
            :class="{hiddenUpload: designFormConfig.data['cover']}"
            :data="{...uploadConfig.uploadEnum.DESIGN}"
            list-type="picture-card"
            ref="uploadFile"
            :file-list="designFormConfig.previewImg.cover"
            :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'cover'})"
            :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'cover'})"
            :on-remove="uploadConfig.onRemove.bind(null,  {key: 'cover'})"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              <div>只能上传jpg/png文件,文件大小限制1M</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item class="auto" label="设计展示图" prop="showImages">
          <el-upload
            :http-request="fileUploadFunction"
            action=""
            :data="{...uploadConfig.uploadEnum.DESIGN}"
            :limit="5"
            :class="[{hiddenUpload: designFormConfig.data.showImages && designFormConfig.data.showImages.length >= 5}]"
            :file-list="designFormConfig.data.showImages"
            :on-exceed="uploadConfig.fileExceed"
            :on-error="uploadConfig.uploadFileError"
            list-type="picture-card"
            ref="uploadFileShowImages"
            :multiple="true"
            :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'showImages'})"
            :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'showImages'})"
            :on-remove="uploadConfig.onRemove.bind(null,  {key: 'showImages'})"
          >
            <i class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              <div>只能上传jpg/png文件,文件大小限制1M</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="出售价格" prop="price">
          <el-input v-model="designFormConfig.data.price">
            <div slot="append">
              RMB (单位:元)
            </div>
          </el-input>
          <el-tooltip class="el-input-question" effect="dark" content="点击查看佣金比例" placement="top">
            <i @click="profitRateDialogConfig.showDialog()" class="el-icon-question"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="原价" prop="originPrice">
          <el-input v-model="designFormConfig.data.originPrice">
            <div slot="append">
              RMB (单位:元)
            </div>
          </el-input>
        </el-form-item>
        <el-form-item label="版权说明" prop="copyright">
          <el-radio-group v-model="designFormConfig.data.copyright">
            <el-radio :label="1">有</el-radio>
            <el-radio :label="0">无</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="designFormConfig.data.copyright === 1">
          <el-form-item label="版权证书" prop="copyrightUrl">
            <el-upload
              :http-request="fileUploadFunction"
              action=""
              class="A4"
              :data="{...uploadConfig.uploadEnum.DESIGN}"
              list-type="picture-card"
              ref="uploadFileCopyrightUrl"
              :file-list="designFormConfig.previewImg.copyrightUrl"
              :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'copyrightUrl'})"
              :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'copyrightUrl'})"
              :on-remove="uploadConfig.onRemove.bind(null,  {key: 'copyrightUrl'})"
            >
              <i class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">
                <div>只能上传jpg/png文件，文件最大允许1M，建议A4纸比例</div>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="售价是否包含版权价格" prop="isContainCopyrightPrice">
            <el-radio-group v-model="designFormConfig.data.isContainCopyrightPrice">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
        <el-form-item label="库存" prop="stock">
          <el-input :maxlength="5" v-model.number="designFormConfig.data.stock"></el-input>
        </el-form-item>
        <el-form-item label="交易形式" prop="transactionForm">
          <el-radio-group v-model="designFormConfig.data.transactionForm">
            <el-radio :label="1">私下交易</el-radio>
            <el-radio :label="2">担保交易</el-radio>
            <el-radio :label="3">两者皆可</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支持发货形式" prop="deliveryForm">
          <el-checkbox-group v-model="designFormConfig.data.deliveryForm">
            <el-checkbox label="2">(自动)网盘下载</el-checkbox>
            <el-checkbox label="3">(自动)网站直接下载</el-checkbox>
            <el-checkbox label="1">(人工)邮件发送</el-checkbox>
            <el-checkbox label="4">(手动，需要有实物)物流邮寄</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <template v-if="designFormConfig.data.deliveryForm.includes('2')">
          <el-form-item label="网盘类型" prop="netdiscName"
                        :rules="{required: true, message: '请选择网盘类型', trigger: ['blur', 'change']}">
            <el-select v-model="designFormConfig.data.netdiscName" placeholder="选择网盘类型">
              <el-option
                v-for="item in netdiscNames"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网盘地址" prop="netdiscUrl">
            <el-input v-model="designFormConfig.data.netdiscUrl" @blur="blur1"></el-input>
          </el-form-item>
          <el-form-item label="网盘密码">
            <el-input v-model="designFormConfig.data.netdiscPwd"></el-input>
          </el-form-item>
          <el-form-item label="解压密码">
            <el-input v-model="designFormConfig.data.netdiscUnzipPwd"></el-input>
          </el-form-item>
        </template>
        <template v-if="designFormConfig.data.deliveryForm.includes('3')">
          <el-form-item class="auto" label="上传原稿" prop="codePath">
            <el-upload
              :http-request="fileUploadFunction"
              action=""
              :data="{...uploadConfig.uploadEnum.DESIGN}"
              drag
              ref="uploadFileCodePath"
              :file-list="designFormConfig.previewImg.codePath"
              :on-error="uploadConfig.uploadFileError"
              :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'codePath'})"
              :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'codePath'})"
              :on-remove="uploadConfig.onRemove.bind(null,  {key: 'codePath'})"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">
                <div>提示：</div>
                <div>1.限制文件最大200M</div>
              </div>
            </el-upload>
          </el-form-item>
        </template>
        <el-form-item class="auto" label="介绍" prop="introduction">
          <ckeditor v-model="designFormConfig.data.introduction"></ckeditor>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="designFormConfig.onSubmitClick()" type="primary">发布
          </el-button>
          <el-button size="small" @click="onTemporaryStorageClick" type="primary">暂存
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog
      center
      title="佣金比例"
      :visible.sync="profitRateDialogConfig.showFlag"
    >
      <profit-rate></profit-rate>
    </el-dialog>
  </div>
</template>

<script>
import validator from "@/resources/js/async-validator";
import {design} from "@/apis";
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../../resources/js/config');
export default {
  name: "edit",
  props: ['id'],
  components: {
    ckeditor: () => import('@CMP/ckeditor/Index'),
    ProfitRate: () => import('@PAGE/common/ProfitRate'),
  },
  data(){
    return {
      profitRateDialogConfig: {
        showFlag: false,
        showDialog: () => {
          this.profitRateDialogConfig.showFlag = true;
        },
      },
      designFormConfig: {
        formName: 'designForm',
        data: {
          // codeType: null,
          designId:null,
          docTypeValue: [],
          type: null,
          subType: null,
          name: null,
          tools: null,
          number: null,
          size: null,
          sizeUnit: 'M',
          cover: null,
          showImages: [],
          price: null,
          originPrice: null,
          copyright: null,
          copyrightUrl: null,
          stock: null,
          transactionForm: null,
          deliveryForm: [],
          introduction: null,
          netdiscName: null,
          netdiscUrl: null,
          netdiscPwd: null,
          netdiscUnzipPwd: null,
          codePath: null,
          isContainCopyrightPrice: null,
        },
        previewImg: {
          cover: [],
          codePath: [],
          copyrightUrl: [],
        },
        rules: {
          netdiscUrl: [
            {required: true, message: '请输入网盘地址', trigger: ['change', 'blur']},
          ],
          // codeType: [
          //   {required: true, message: '请选择设计类型', trigger: ['change', 'blur']},
          // ],
          docTypeValue: [
            {required: true, message: '请选择设计分类', trigger: ['change', 'blur']},
          ],
          name: [
            {required: true, message: '请输入设计名称', trigger: ['change', 'blur']},
          ],
          tools: [
            {required: true, message: '请选择设计工具', trigger: ['change', 'blur']},
          ],
          number: [
            {required: true, message: '请输入设计个数', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validInteger(rule, value, callback);
                }
              },
              message: '数值不合法，只能输入整数'
            },
            {
              validator: (rule, value, callback) => {
                if (value<0) {
                  callback(new Error("数值不合法，只能输入正整数"));
                }else {
                  callback()
                }
              }
            }

          ],
          size: [
            {required: true, message: '请输入设计大小', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validNum(rule, value, callback);
                }
              },
              message: '数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                if (value<0) {
                  callback(new Error("数值不合法，只能输入正整数"));
                }else {
                  callback()
                }
              }
            }
          ],
          cover: [
            {required: true, message: '请上传设计封面', trigger: ['change', 'blur']},
          ],
          showImages: [
            {required: true, message: '请上传展示图', trigger: ['change', 'blur']},
          ],
          isContainCopyrightPrice: [
            {required: true, message: '请确认价格', trigger: ['change', 'blur']},
          ],
          copyright: [
            {required: true, message: '请选择版权说明', trigger: ['change', 'blur']},
          ],
          copyrightUrl: [
            {required: true, message: '请上传版权证书', trigger: ['change', 'blur']},
          ],
          price: [
            {required: true, message: '请输入价格', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validMoney(rule, value, callback);
                }
              },
              message: '数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                let {originPrice: realPrice} = this.designFormConfig.data;
                realPrice = parseFloat(realPrice);
                value = parseFloat(value);
                if ((realPrice || realPrice === 0) && realPrice < value) {
                  callback(new Error(rule.message))
                } else {
                  callback()
                }
              },
              message: '售价不能大于原价'
            },
          ],
          originPrice: [
            {required: true, message: '请输入原价', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                } else {
                  validator.validMoney(rule, value, callback);
                }
              },
              message: '数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                let {price: salePrice} = this.designFormConfig.data;
                salePrice = parseFloat(salePrice);
                value = parseFloat(value);
                if (value && salePrice && salePrice > value) {
                  callback(new Error(rule.message))
                } else {
                  callback()
                }
              },
              message: '原价不能小于售价'
            },
          ],
          stock: [
            {required: true, message: '请输入库存', trigger: ['change', 'blur']},
            {
              validator: validator.validInteger,
              message: '数值不合法'
            },
            {
              validator: (rule, value, callback) => {
                if (value > 10000) {
                  callback('最大10000');
                } else if (value <= 0) {
                  callback('库存不能小于1');
                } else {
                  callback();
                }
              },
            },
          ],
          transactionForm: [
            {required: true, message: '请选择交易形式', trigger: ['change', 'blur']},
          ],
          deliveryForm: [
            {required: true, message: '请选择发货形式', trigger: ['change', 'blur']},
          ],
          introduction: [
            {required: true, message: '请输入设计介绍', trigger: ['change', 'blur']},
          ],
          codePath: [
            {required: true, message: '请上传原稿', trigger: ['change', 'blur']},
          ],
        },
        condition: {
          tools: [],
          // codeType: [],
          types: [],
          subTypes: []
        },
        loadCondition: () => {
          design.loadCondition()
          .then(res => {
            let {data} = res;
            if (data) {
              Object.keys(data).forEach(key => {
                this.designFormConfig.condition[key] = data[key];
              })
            }
          });
        },
        loadDetail: () => {

          design.editdetail({id: this.id, userId: this.user.userId}).then(res => {
            let {data} = res;
            let {data: form} = this.designFormConfig;
            form.codeType = data.codetype;
            form.type = data.designtype;
            form.subType = data.designsubtype;
            form.name = data.designname;
            form.tools = data.devetools;
            form.number = data.fileamount;
            form.size = data.filesize;
            form.sizeUnit = data.sizeunit;
            form.cover = data.designcoverImgurl;
            form.showImages = data.showimgs.map(item => {
              return {
                url: item,
                response: {
                  src: item,
                },
              }
            });
            form.price = data.saleprice;
            form.originPrice = data.realprice;
            form.copyright = data.copyrightflag;
            form.copyrightUrl = data.copyrightImgurl;
            form.stock = data.stock;
            form.transactionForm = data.dealmodel;
            form.deliveryForm = data.saletype.split(',');
            form.introduction = data.introduce;
            form.netdiscName = data.netdiscname;
            form.netdiscUrl = data.netdiscurl;
            form.netdiscPwd = data.netdiscpwd;
            form.netdiscUnzipPwd = data.netdiscunzippwd;
            form.codePath = data.codepath;
            form.isContainCopyrightPrice = data.iscopyrightprice;
            form.subType = data.designsubtype;
            form.type = data.designtype;
            form.docTypeValue = [data.designtype, data.designsubtype];

            this.designFormConfig.previewImg.cover = [{
              url: data.designcoverImgurl,
            }];
            if (data.copyrightImgurl) {
              this.designFormConfig.previewImg.copyrightUrl = [{
                url: data.copyrightImgurl,
              }];
            }
            if (data.codepath) {
              this.designFormConfig.previewImg.codePath = [{
                url: data.codepath,
                name: data.codepath.substring(data.codepath.lastIndexOf('/') + 1),
              }];
            }
            this.designFormConfig.data = form;
          });
        },
        onSubmitClick: () => {
          this.$refs[this.designFormConfig.formName].validate().then(res => {
            this.designFormConfig.doSubmitForm();
          }).catch((err) => {
            this.$message.warning('请完成表单');
          });
        },
        doSubmitForm: (temp=false) => {
          let {data} = this.designFormConfig;
          design.persist({
            designId: this.designFormConfig.data.designId,
            codeType: data.codeType,
            type: data.type,
            subType: data.subType,
            name: data.name,
            tools: data.tools,
            number: data.number,
            size: data.size,
            sizeUnit: data.sizeUnit,
            netdiscName: data.netdiscName,
            cover: data.cover,
            showImages: data.showImages.map(item => item.response.src)
            .map(item => item.substring(item.indexOf('/upload/'))).join(','),
            price: data.price,
            originPrice: data.originPrice,
            copyright: data.copyright,
            copyrightUrl: data.copyrightUrl,
            stock: data.stock,
            transactionForm: data.transactionForm,
            deliveryForm: data.deliveryForm,
            introduction: data.introduction,
            netdiscUrl: data.netdiscUrl,
            netdiscPwd: data.netdiscPwd,
            netdiscUnzipPwd: data.netdiscUnzipPwd,
            codePath: data.codePath,
            isContainCopyrightPrice: data.isContainCopyrightPrice,
            userId: this.user.userId,
            temporary_storage: temp
          }).then(res => {
            this.$message.success('提交成功！');
            this.goPage({
              path: '/shop/sale/design'
            })
          })
        }
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
        if (response.code){
          switch (key) {
            case 'cover':
              this.designFormConfig.data[key] = response.src;
              this.$message.success('上传文件成功')
              if (fileList.length > 1) {
                fileList.splice(0, 1);
              }
              break;
            case 'copyrightUrl':
              this.designFormConfig.data[key] = response.src;
              if (fileList.length > 1) {
                fileList.splice(0, 1);
              }
              break;
            case 'showImages':
              this.designFormConfig.data[key] = fileList;
              break;
            case 'codePath':
              this.designFormConfig.data[key] = response.src;
              console.log(2)
              console.log(this.designFormConfig)
              if (fileList.length > 1) {
                fileList.splice(0, 1);
              }
              break;
          }
          this.$refs[this.designFormConfig.formName].validateField([key]);
          this.$message.success('文件上传成功！');
        }else {
          let uid = file.uid
          if (key == 'cover'){
            let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid)
            this.$refs.uploadFile.uploadFiles.splice(idx, 1)
          }else if(key == 'copyrightUrl'){
            let idx = this.$refs.uploadFileCopyrightUrl.uploadFiles.findIndex(item => item.uid === uid)
            this.$refs.uploadFileCopyrightUrl.uploadFiles.splice(idx, 1)
          }else if(key == 'showImages'){
            let idx = this.$refs.uploadFileShowImages.uploadFiles.findIndex(item => item.uid === uid)
            this.$refs.uploadFileShowImages.uploadFiles.splice(idx, 1)
          }else if(key == 'codePath'){
            let idx = this.$refs.uploadFileCodePath.uploadFiles.findIndex(item => item.uid === uid)
            this.$refs.uploadFileCodePath.uploadFiles.splice(idx, 1)
          }
            this.$message.error('上传文件失败')
          }
        },
        onRemove: ({key}, file, fileList) => {
          switch (key) {
            case 'cover':
              this.designFormConfig.data[key] = null;
              fileList.splice(0, fileList.length);
              break;
            case 'copyrightUrl':
              this.designFormConfig.data[key] = null;
              fileList.splice(0, fileList.length);
              break;
            case 'showImages':
              this.designFormConfig.data[key] = fileList;
              break;
            case 'codePath':
              this.designFormConfig.data[key] = null;
              fileList.splice(0, fileList.length);
              break;
          }
          console.log(this.designFormConfig.data)
        },
        beforeUpload: ({key}, file) => {

          let result = false;
          if (['copyrightUrl', 'cover', 'showImages'].includes(key)) {
            const allowType = [
              'image/png',
              'image/jpg'
            ];
            const uploadType = ['jpg','png','bmp'];
            const filetype=file.name.replace(/.+\./,'');
            const isMatchType =
              uploadType.indexOf(filetype.toLowerCase())!= -1;
            const isLtSize = file.size / 1024 / 1024 < 1;
            if (!isMatchType) {
              this.$message.error('上传文件只能是 图片 格式!');
              return false;
            }
            if (!isLtSize) {
              this.$message.error(`上传文件大小不能超过 1M!`);
              return false;
            }
            result = isMatchType && isLtSize;
          } else if (key === 'codePath') {
            const isLtSize = file.size / 1024 / 1024 < 200;
            if (!isLtSize) {
              this.$message.error(`上传文件大小不能超过 200MB!`);
              return false;
            }
            result = isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      }
    }
  },
  methods: {
    onDocTypeCascaderChange(value) {
      this.designFormConfig.data.type = value[0];
      this.designFormConfig.data.subType = value[1];
    },
    submitFormData(temp = false) {
      const formData = this.designFormConfig.data;
      this.$request.post({
        reqcode: '1012',
        reqdesc: '编辑商品',

      }).then(res => {
        const {goodsId} = res;
        this.goodsId = goodsId;
        this.$message.success('保存成功');
        this.$router.push('/shop/sale/sourcecode')
      })
    },
    getDesignDetail() {
      if (!this.designId) {
        return;
      }
      design.editdetail({
        id: this.designId,
        userId: this.user.userId
      }).then(res => {
        const {data, design = []} = res;
        console.log("dddddddd")
        console.log(data)
        if (data) {
          this.designFormConfig.data.designId=data.designid;
          this.designFormConfig.data.name = data.designname || null;
          this.designFormConfig.data.docTypeValue=[data.designtype, data.designsubtype];
          this.designFormConfig.data.tools=data.devetools || null;
          this.designFormConfig.data.number=data.fileamount || null;
          this.designFormConfig.data.size=data.filesize || null;
          this.designFormConfig.data.sizeUnit=data.sizeunit || null;
          this.designFormConfig.data.price=data.saleprice || null;
          this.designFormConfig.data.originPrice=data.realprice || null;
          this.designFormConfig.data.copyright=data.copyrightflag;
          this.designFormConfig.data.stock=data.stock || null;
          this.designFormConfig.data.transactionForm=data.dealmodel || null;
          this.designFormConfig.data.introduction=data.introduce || null;
          this.designFormConfig.data.deliveryForm=data.saletype.split(',') ||null;
          this.designFormConfig.data.netdiscName=data.netdiscname;
          this.designFormConfig.data.netdiscUrl=data.netdiscurl;
          this.designFormConfig.data.netdiscPwd=data.netdiscpwd;
          this.designFormConfig.data.netdiscUnzipPwd=data.netdiscunzippwd;
          this.designFormConfig.data.copyrightUrl = data.copyrightImgurl;
          this.designFormConfig.data.docTypeValue = [data.designtype, data.designsubtype];
          this.designFormConfig.data.subType = data.designsubtype;
          this.designFormConfig.data.type = data.designtype;
          this.designFormConfig.data.isContainCopyrightPrice = data.iscopyrightprice;
          this.designFormConfig.data.codePath = data.codepath
          this.designFormConfig.data.showImages = data.showimgs.map(item => {
            return {
              url: item,
              response: {
                src: item,
              },
            }
          });
          if (data.designcoverImgurl){
            this.designFormConfig.previewImg.cover = [{
              url: data.designcoverImgurl,
            }];
            this.designFormConfig.data.cover = data.designcoverImgurl.substring(data.designcoverImgurl.indexOf('/upload/'));
          }
          if (data.copyrightImgurl) {
            this.designFormConfig.previewImg.copyrightUrl = [{
              url: data.copyrightImgurl,
            }];
          }
          if (data.codepath) {
            this.designFormConfig.previewImg.codePath = [{
              url: data.codepath,
              name: data.codepath.substring(data.codepath.lastIndexOf('/') + 1),
            }];
          }
          console.log(this.designFormConfig)
        }
      })
    },
    onTemporaryStorageClick() {
      const {designFormConfig: formData} = this;
      console.log('zzasdasd')
      console.log(formData.data)
      let checkField = [];
      let exclude = ['typeValue'];
      Object.keys(formData.data).forEach(key => {
        if (exclude.includes(1)) {
          this.$alert('baga!')
          return;
        }
        let value = formData[key];
        let needCheck = false;
      })
      this.designFormConfig.doSubmitForm(true);
      // if (checkField.length === 0) {
      //   this.submitFormData(true);
      // } else {
      //   let hasError;
      //   let counter = 0;
      //   this.submitFormData(true);
      // this.$refs['goodsForm'].validateField(checkField, (errorMessage => {
      //   ++counter;
      //   if (errorMessage) {
      //     if (!hasError) {
      //       hasError = true;
      //       this.$message.warning(errorMessage);
      //     }
      //   } else if (!hasError && counter === checkField.length) {
      //     this.submitFormData(true);
      //   }
      // }));
      // }
    },
    blur1(){
      if(!(/^[hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/.test(this.designFormConfig.data.netdiscUrl)))
      {
        this.$alert('网盘地址错误，http或https开头加域名地址');
        this.designFormConfig.data.netdiscUrl=null;
      }
    }
  },
  beforeMount() {
    const {category,designId} = this.getQuery();
    // if (!this.id && !category) {
    //   this.$router.push('/release/sourcecode/2');
    //   return
    // }
    console.log(this.designFormConfig)
    if (category) {
      this.designFormConfig.data.docTypeValue = [category[0].typeId, category[1].typeId];
      this.designFormConfig.data.type = category[0].typeId;
      this.designFormConfig.data.subType = category[1].typeId;
    }
    this.designFormConfig.loadCondition();
    if (this.id) {
      this.designFormConfig.loadDetail();
    }

    if (designId) {
      this.designId = designId;
      this.getDesignDetail();
    }
  },
  computed: {
    docTypeOptions() {
      let options = [];
      let types = this.designFormConfig.condition.types;
      if (types && types.length > 0) {
        options = types.map(type => {
          return {
            label: type.typeName,
            value: type.typeId,
            children: type.children.map(item => {
              return {
                label: item.typeName,
                value: item.typeId,
              }
            })
          }
        })
      }



      return options;
    },
    toolsOptions() {
      let options = [];
      const type = this.designFormConfig.condition.tools;
      if (type && type.length > 0) {
        options = type.map(item => {
          return {
            value: item.toolsname,
            label: item.toolsname
          }
        })
      }
      return options;
    },
    netdiscNames() {
      let options = [];
      const names = this.designFormConfig.condition.netdiscName;
      if (names && names.length > 0) {
        options = names.map(item => {
          return {
            value: item.dropdownvalue,
            label: item.dropdownvalue
          }
        })
      }
      return options;
    },
    sizeUnit() {
      return [
        {
          label: 'KB',
          value: 1
        },
        {
          label: 'M',
          value: 1024
        }
      ]
    },
  },
}
</script>

<style scoped lang="scss">
.design {

  /deep/ {
    .el-form-item {
      .el-input-question {
        position: absolute;
        right: -25px;
        top: 14px;
        cursor: pointer;
      }
    }

    .A4 {
      .el-upload-list--picture-card .el-upload-list__item {
        height: 210px;
        line-height: 210px;
      }

      .el-upload--picture-card {
        height: 210px;
        line-height: 210px;
      }
    }
  }

  & /deep/ .el-card__header .title {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 3px;
      background: $main-color;
      top: 0;
      bottom: 0;
      left: -10px;
    }
  }

  .designForm {
    & /deep/ .el-form-item:not(.auto) {
      width: 500px;
    }


  }
}

</style>
